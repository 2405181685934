import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { INotificationPOSTRequest, httpOptions } from '../global.types'
import { CacheMapService } from './caching/cache-map.service'
import {
  IAccessRole,
  IBowl,
  IBowlSetup,
  IDishCatalogue,
  IDisplayTemplate,
  IGateway,
  ILocation,
  IMenu,
  IMenuDish,
  IOrganization,
  IMenuPrintTemplate,
  IProcurementDataFile,
  IProcurementProduct,
  IRawIngredient,
  IRecipe,
  IScale,
  IMenuSectionTemplate,
  IMenuTemplate,
  ISubsidiary,
  ITag,
  ITrashTemplate,
  IUser,
  ITrackingTemplate,
  ITrackingGroupTemplate,
  ITracking,
  ITrackingGroup
} from '../global.models'
import { MatSnackBar } from '@angular/material/snack-bar'

export const apiURLs =
  //Object.assign({}, translation_api_URLs, menu_api_URLs, profile_api_URLs, tracking_api_URLs, data_insights_api_URLs, legacy_api_URLs)

  /* export const translation_api_URLs = */ {
    translationSuggestionsUrlV3: '/v3/translation_api/translation_suggestions',
    translations: '/v3/translation_api/translations',
    RecipeNamesV3: '/v3/translation_api/recipe_names',
    RecipeDescriptionsV3: '/v3/translation_api/recipe_descriptions',
    rawIngredientNamesV3: '/v3/translation_api/raw_ingredient_names',
    sectionTemplatePlaceholderNamesV3: '/v3/translation_api/section_template_placeholder_names',
    catalogueNamesV3: '/v3/translation_api/catalogue_names',
    trashTemplateNamesV3: '/v3/translation_api/trash_template_names',
    trashTemplateDescriptionsV3: '/v3/translation_api/trash_template_descriptions',
    tag_names: '/v3/translation_api/tag_names',
    // }
    // export const menu_api_URLs = {
    menusUrlV3: '/v3/menu_api/menus',
    menu_sections: '/v3/menu_api/menu_sections',
    menuDishesUrlV3: '/v3/menu_api/menu_dishes',
    menu_templates: '/v3/menu_api/menu_templates',
    menu_section_templates: '/v3/menu_api/menu_section_templates',
    // }
    // export const dish_api_URLs = {
    allergensUrlV3: '/v3/dish_api/allergens',
    dishCatalogueAllocationUrlV3: '/v3/dish_api/dish_catalogue_allocation',
    RecipesUrlV3: '/v3/dish_api/recipes',
    tags: '/v3/dish_api/tags',
    foods: '/v3/dish_api/foods',
    userDishFavoritesUrl: '/v3/dish_api/user_dish_favorites',
    ai_recipe_foods: '/v3/dish_api/ai_recipe_foods',
    // }
    // export const profile_api_URLs = {
    menuPrintTemplateUrl: '/v3/profile_api/menu_print_templates',
    standardCataloguesUrlV3: '/v3/profile_api/standard_catalogues',
    customCataloguesUrlV3: '/v3/profile_api/custom_catalogues',
    custom_catalogue_visibility: '/v3/profile_api/custom_catalogue_visibility',
    standard_catalogue_visibility: '/v3/profile_api/standard_catalogue_visibility',
    usersUrlV3: '/v3/profile_api/users',
    subsidiariesUrlV3: '/v3/profile_api/subsidiaries',
    locationsUrlV3: '/v3/profile_api/locations',
    displayTemplatesUrlV3: '/v3/profile_api/display_templates',
    trash_templates: '/v3/profile_api/trash_templates',
    bowl_setups: '/v3/profile_api/bowl_setups',
    bowls: '/v3/profile_api/bowls',
    organizationsUrlV3: '/v3/profile_api/organizations',
    blob_files: '/v3/profile_api/blob_files',
    // }
    // export const tracking_api_URLs = {
    tracking_groups: '/v3/tracking_api/tracking_groups',
    trackings: '/v3/tracking_api/trackings',
    tracking_templates: '/v3/tracking_api/tracking_templates',
    tracking_group_templates: '/v3/tracking_api/tracking_group_templates',
    // }
    // export const procurement_api_URLs = {
    ingredient_list: '/v3/procurement_api/ingredient_list',
    providers: '/v3/procurement_api/providers',
    procurement_data: '/v3/procurement_api/procurement_data',
    ai_column_matching: '/v3/procurement_api/ai_column_matching',
    // }
    // export const data_insights_api_URLs = {
    selected_trash_bowls: '/v3/data_insights_api/selected_trash_bowls',
    selected_bowl_setups: '/v3/data_insights_api/selected_bowl_setups',
    graph_data: '/v3/reporting_api/graph_data',
    insights: '/v3/reporting_api/insights',
    procurement_insights: '/v3/reporting_api/procurement_reporting',
    // }
    // export const legacy_api_URLs = {
    menusUrlV2: '/v2/menus',
    users: '/users',
    display_templates: '/display_templates',
    print_templates: '/print_templates',
    locations: '/locations',
    location_settings: '/locations/settings',
    locationmenus: '/locationmenus',
    scale_dishesV2: '/v2/scale_dishes',
    frontend_errors: '/v3/logging_api/frontend_errors',
    // }
    // export const admin_api_URLs = {
    organizationsAdminURL: '/v3/admin/organizations',
    subsidiariesAdminURL: '/v3/admin/subsidiaries',
    locationsAdminUrl: '/v3/admin/locations',
    usersAdminURL: '/v3/admin/users',
    gatewaysAdminURL: '/v3/admin/gateways',
    scalesAdminURL: '/v3/admin/scales',
    subsidiaryDataUrl: '/v3/admin/reporting_api/reporting_data',
    mender_status: '/v3/admin/mender_status',
    admin_trackings: '/v3/admin/trackings',
    admin_scale_dishes: '/v3/admin/scale_dishes',
    // }
    // export const admin_legacy_api_URLs = {
    notificationUrl: '/admin/notification',
    scaleSwapURL: '/v3/admin_api/scale_swap',
    accessRolesURL: '/v3/admin/access_roles',
    // }
    // export const unauthorized_api_URLS = {
    subsidiaries_open: '/v3/subsidiaries_open',
    locations_open: '/v3/profile_api/locations_open',
    insights_open: '/v3/reporting_api/insights_open',
    menus_open: '/v3/menu_api/menus_open',
    allergens_open: '/v3/dish_api/allergens_open'
  }

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  constructor(private http: HttpClient, private cacheMapService: CacheMapService, @Inject('environment') private environment, private snackBar: MatSnackBar) {}

  loadMenuByID(params): Observable<IMenu> {
    httpOptions['params'] = params
    return this.http.get<IMenu>(this.environment.apiEndpoint + apiURLs.menusUrlV2, httpOptions).pipe(catchError(this.handleError<IMenu>('loadMenuByID')))
  }

  /***********************************************************/
  /*                        Insights                         */
  /***********************************************************/

  loadInsights(params: any, body: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.insights, body, httpOptions).pipe(catchError(this.handleError<any>('loadInsights')))
  }

  loadProcurementInsights(params: any, body: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.procurement_insights, body, httpOptions).pipe(catchError(this.handleError<any>('loadProcurementInsights')))
  }

  loadInsightsOpen(params: any, body: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.insights_open, body, httpOptions).pipe(catchError(this.handleError<any>('loadInsights')))
  }

  loadPresentationDataForScaleDish(params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.get<any>(this.environment.apiEndpoint + apiURLs.graph_data, httpOptions).pipe(catchError(this.handleError<any>('loadPresentationDataForScaleDish')))
  }

  updateInsightsIncluded(params: any, query_filters: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.insights, query_filters, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('insight')),
      catchError(this.handleError<any>('updateInsightsIncluded'))
    )
  }

  updateScaleDish(scale_dish, params) {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiURLs.scale_dishesV2, scale_dish, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('scale_dish')),
      catchError(this.handleError('updateScaleDish'))
    )
  }

  /***********************************************************/
  /*                    Organization                         */
  /***********************************************************/
  loadOrganization(params): Observable<IOrganization> {
    httpOptions['params'] = params
    return this.http.get<IOrganization>(this.environment.apiEndpoint + apiURLs.organizationsUrlV3, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IOrganization>('loadOrganization'))
    )
  }

  getAllOrganizations(params): Observable<IOrganization[]> {
    httpOptions['params'] = params
    return this.http.get<IOrganization[]>(this.environment.apiEndpoint + apiURLs.organizationsAdminURL, httpOptions).pipe(catchError(this.handleError<IOrganization[]>('getAllOrganizations')))
  }

  updateOrganization(organization: IOrganization): Observable<IOrganization> {
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.organizationsAdminURL, organization, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('organization')),
      map((results) => results[0]),
      catchError(this.handleError<any>('saveOrganization'))
    )
  }
  createOrganization(organization: IOrganization): Observable<IOrganization> {
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.organizationsAdminURL, organization, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('organization')),
      map((results) => results[0]),
      catchError(this.handleError<any>('saveOrganization'))
    )
  }

  /***********************************************************/
  /*                      Subsidiary                         */
  /***********************************************************/

  getSubsidiary(params): Observable<ISubsidiary> {
    httpOptions['params'] = params
    return this.http.get<ISubsidiary>(this.environment.apiEndpoint + apiURLs.subsidiariesUrlV3, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.showErrorSnackBar()),
      catchError(this.handleError<ISubsidiary>('getSubsidiary'))
    )
  }

  createSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.subsidiariesAdminURL, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiar')),
      map((results) => results[0]),
      catchError(this.handleError<any>('createSubsidiary'))
    )
  }

  updateSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.subsidiariesAdminURL, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiar')),
      map((results) => results[0]),
      catchError(this.handleError<any>('updateSubsidiary'))
    )
  }

  /***********************************************************/
  /*                       Locations                         */
  /***********************************************************/
  loadLocation(params): Observable<ILocation> {
    httpOptions['params'] = params
    return this.http.get<ILocation>(this.environment.apiEndpoint + apiURLs.locationsUrlV3, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<ILocation>('loadLocation'))
    )
  }

  loadLocations(params): Observable<ILocation[]> {
    httpOptions['params'] = params
    return this.http.get<ILocation[]>(this.environment.apiEndpoint + apiURLs.locationsUrlV3, httpOptions).pipe(catchError(this.handleError<ILocation[]>('loadLocations')))
  }

  updateLocation(location: ILocation): Observable<ILocation> {
    httpOptions['params'] = {}
    return this.http.put<ILocation>(this.environment.apiEndpoint + apiURLs.locationsAdminUrl, location, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<ILocation>('saveLocation'))
    )
  }

  createLocation(location: ILocation): Observable<ILocation> {
    httpOptions['params'] = {}
    return this.http.post<ILocation>(this.environment.apiEndpoint + apiURLs.locationsAdminUrl, location, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<ILocation>('saveLocation'))
    )
  }

  /***********************************************************/
  /*                         Users                           */
  /***********************************************************/

  getProfileV3(params): Observable<IUser> {
    httpOptions['params'] = params
    return this.http.get<IUser>(this.environment.apiEndpoint + apiURLs.usersUrlV3, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IUser>('getProfileV3'))
    )
  }

  saveProfile(profile: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.environment.apiEndpoint + apiURLs.usersUrlV3, profile, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user')),
      catchError(this.handleError<any>('saveProfile'))
    )
  }

  changeSubsidiaryForUser(params): Observable<IUser> {
    httpOptions['params'] = params
    return this.http.put<IUser>(this.environment.apiEndpoint + apiURLs.usersUrlV3, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user')),
      catchError(this.handleError<IUser>('changeSubsidiaryForUser'))
    )
  }

  getAllUsers(params): Observable<object> {
    httpOptions['params'] = params
    return this.http.get<object>(this.environment.apiEndpoint + apiURLs.usersAdminURL, httpOptions).pipe(catchError(this.handleError<object>('getAllUsers')))
  }

  createUser(profile: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.environment.apiEndpoint + apiURLs.usersAdminURL, profile, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user')),
      catchError(this.handleError<any>('createUser'))
    )
  }

  updateUser(profile: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.environment.apiEndpoint + apiURLs.usersAdminURL, profile, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user')),
      catchError(this.handleError<any>('saveProfile'))
    )
  }
  /***********************************************************/
  /*                     Menu Dishes                         */
  /***********************************************************/

  updateMenuDish(menu_dish: IMenuDish, params): Observable<IMenuDish> {
    httpOptions['params'] = params
    return this.http.put<IMenuDish>(this.environment.apiEndpoint + apiURLs.menuDishesUrlV3, menu_dish, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      }),
      catchError(this.handleError<any>('updateMenuDish'))
    )
  }

  removeDishFromMenuSection(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete<any>(this.environment.apiEndpoint + apiURLs.menuDishesUrlV3, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('removeDishFromMenuSection'))
    )
  }

  /***********************************************************/
  /*                      Raw Dishes                         */
  /***********************************************************/
  getRecipes(params): Observable<IRecipe[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiURLs.RecipesUrlV3, httpOptions)
  }

  getRecipe(id: string, params): Observable<IRecipe> {
    httpOptions['params'] = params
    return this.http
      .get<IRecipe>(this.environment.apiEndpoint + apiURLs.RecipesUrlV3 + '/' + id, httpOptions)
      .pipe(map((results) => results[0]))
      .pipe(
        map((dish) => {
          return dish['recipes'][0] // Quick fix to return only one recipe
        })
      )
  }

  updateRecipe(recipe: IRecipe, params): Observable<IRecipe> {
    httpOptions['params'] = params
    return this.http.put<IRecipe>(this.environment.apiEndpoint + apiURLs.RecipesUrlV3, recipe, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      }),
      catchError(this.handleError<any>('updateRecipe'))
    )
  }

  createRecipe(recipe: IRecipe, params): Observable<IRecipe> {
    httpOptions['params'] = params
    return this.http.post<IRecipe>(this.environment.apiEndpoint + apiURLs.RecipesUrlV3, recipe, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      }),
      catchError(this.handleError<any>('createRecipe'))
    )
  }

  // My raw dish favorites:
  addDishToMyDishes(recipe_id: string): Observable<IRecipe> {
    return this.http.put<IRecipe>(this.environment.apiEndpoint + apiURLs.userDishFavoritesUrl + '/' + recipe_id, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user_dish')),
      catchError(this.handleError<IRecipe>('addDishToMyDishes'))
    )
  }

  removeDishFromMyDishes(recipe_id: string): Observable<any> {
    return this.http.delete(this.environment.apiEndpoint + apiURLs.userDishFavoritesUrl + '/' + recipe_id, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user_dish')),
      catchError(this.handleError('removeDishFromMyDishes'))
    )
  }

  /***********************************************************/
  /*                   Raw ingredients                       */
  /***********************************************************/

  getRawIngredients(params): Observable<{ ingredients: IRawIngredient[]; products: IRawIngredient[] } | IRawIngredient[]> {
    httpOptions['params'] = params
    return this.http.get<{ ingredients: IRawIngredient[]; products: IRawIngredient[] } | IRawIngredient[]>(this.environment.apiEndpoint + apiURLs.foods, httpOptions)
  }

  createRawIngredient(params, raw_ingredient): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.foods, raw_ingredient, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('foods')))
  }

  getAllergens(): Observable<any> {
    return this.http.get<any>(this.environment.apiEndpoint + apiURLs.allergensUrlV3, httpOptions).pipe(catchError(this.handleError<any>('getAllergens')))
  }

  getIngredientSuggestions(params): Observable<IRawIngredient[]> {
    httpOptions['params'] = params
    return this.http.get<IRawIngredient[]>(this.environment.apiEndpoint + apiURLs.ai_recipe_foods, httpOptions).pipe(catchError(this.handleError<IRawIngredient[]>('getIngredientSuggestions')))
  }

  getIngredientInfo(ingredients: IRawIngredient[], params): Observable<IRawIngredient[]> {
    httpOptions['params'] = params
    return this.http.post<IRawIngredient[]>(this.environment.apiEndpoint + apiURLs.ai_recipe_foods, ingredients, httpOptions).pipe(catchError(this.handleError<IRawIngredient[]>('getIngredientInfo')))
  }

  getAllergensOpen(): Observable<any> {
    return this.http.get<any>(this.environment.apiEndpoint + apiURLs.allergens_open, httpOptions).pipe(catchError(this.handleError<any>('getAllergens')))
  }
  /***********************************************************/
  /*                    Dish catalogues                      */
  /***********************************************************/

  getStandardDishCatalogues(params): Observable<IDishCatalogue[]> {
    httpOptions['params'] = params
    return this.http.get<IDishCatalogue[]>(this.environment.apiEndpoint + apiURLs.standardCataloguesUrlV3, httpOptions).pipe(catchError(this.handleError<IDishCatalogue[]>('getStandardDishCatalogues')))
  }

  getCustomDishCatalogues(params): Observable<IDishCatalogue[]> {
    httpOptions['params'] = params
    return this.http.get<IDishCatalogue[]>(this.environment.apiEndpoint + apiURLs.customCataloguesUrlV3, httpOptions).pipe(catchError(this.handleError<IDishCatalogue[]>('getCustomDishCatalogues')))
  }

  createDishCatalogue(dish_catalogue: IDishCatalogue, params): Observable<IDishCatalogue> {
    httpOptions['params'] = params
    return this.http.post<IDishCatalogue>(this.environment.apiEndpoint + apiURLs.customCataloguesUrlV3, dish_catalogue, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<IDishCatalogue>('createDishCatalogue'))
    )
  }

  updateDishCatalogue(dish_catalogue: IDishCatalogue, params): Observable<IDishCatalogue> {
    httpOptions['params'] = params
    return this.http.put<IDishCatalogue>(this.environment.apiEndpoint + apiURLs.customCataloguesUrlV3, dish_catalogue, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<IDishCatalogue>('updateDishCatalogue'))
    )
  }

  updateStandardDishCatalogueVisibility(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.standard_catalogue_visibility, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<any>('updateStandardDishCatalogueVisibility'))
    )
  }

  updateCustomDishCatalogueVisibility(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.custom_catalogue_visibility, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<any>('updateCustomDishCatalogueVisibility'))
    )
  }

  updateDishCatalogueAllocations(recipe_id: string, catalogue_ids: string[], params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.dishCatalogueAllocationUrlV3 + '/' + recipe_id, catalogue_ids, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<any>('updateDishCatalogueAllocations'))
    )
  }

  /***********************************************************/
  /*                   Menu templates                     */
  /***********************************************************/

  getMenuTemplate(params): Observable<IMenuTemplate> {
    httpOptions['params'] = params
    return this.http.get<IMenuTemplate>(this.environment.apiEndpoint + apiURLs.menu_templates, httpOptions).pipe(map((results) => results[0]))
  }

  getMenuTemplates(params): Observable<IMenuTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<IMenuTemplate[]>(this.environment.apiEndpoint + apiURLs.menu_templates, httpOptions)
  }

  createMenuTemplate(params: any, template: IMenuTemplate): Observable<IMenuTemplate> {
    httpOptions['params'] = params
    return this.http.post<IMenuTemplate>(this.environment.apiEndpoint + apiURLs.menu_templates, template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_template')),
      catchError(this.handleError<IMenuTemplate>('createMenuTemplate'))
    )
  }

  updateMenuTemplate(params: any, template: IMenuTemplate): Observable<IMenuTemplate> {
    httpOptions['params'] = params
    return this.http.put<IMenuTemplate>(this.environment.apiEndpoint + apiURLs.menu_templates, template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_template')),
      catchError(this.handleError<IMenuTemplate>('updateMenuTemplate'))
    )
  }

  updateMenuSectionTemplate(params: any, template: IMenuSectionTemplate): Observable<IMenuSectionTemplate> {
    httpOptions['params'] = params
    return this.http.put<IMenuSectionTemplate>(this.environment.apiEndpoint + apiURLs.menu_section_templates, template, httpOptions).pipe(
      map((results) => results[0]),
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_section_template')),
      catchError(this.handleError<IMenuSectionTemplate>('updateMenuSectionTemplate'))
    )
  }

  /***********************************************************/
  /*                   Menu templates                     */
  /***********************************************************/

  createTrackingTemplate(params: any, template: ITrackingTemplate): Observable<ITrackingTemplate> {
    httpOptions['params'] = params
    return this.http.post<ITrackingTemplate>(this.environment.apiEndpoint + apiURLs.tracking_templates, template, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('tracking_template'), this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')
      })
    )
  }

  updateTrackingTemplate(params: any, template: ITrackingTemplate): Observable<ITrackingTemplate> {
    httpOptions['params'] = params
    return this.http.put<ITrackingTemplate>(this.environment.apiEndpoint + apiURLs.tracking_templates, template, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('tracking_template'), this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')
      })
    )
  }

  getTrackingGroupTemplates(params): Observable<ITrackingGroupTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<ITrackingGroupTemplate[]>(this.environment.apiEndpoint + apiURLs.tracking_group_templates, httpOptions)
  }
  createTrackingGroupTemplate(params: any, template: ITrackingGroupTemplate): Observable<ITrackingGroupTemplate> {
    httpOptions['params'] = params
    return this.http.post<ITrackingGroupTemplate>(this.environment.apiEndpoint + apiURLs.tracking_group_templates, template, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')))
  }

  updateTrackingGroupTemplate(params: any, template: ITrackingGroupTemplate): Observable<ITrackingGroupTemplate> {
    httpOptions['params'] = params
    return this.http.put<ITrackingGroupTemplate>(this.environment.apiEndpoint + apiURLs.tracking_group_templates, template, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')))
  }

  /***********************************************************/
  /*                     Display templates                     */
  /***********************************************************/
  loadDisplayTemplates(): Observable<IDisplayTemplate[]> {
    httpOptions['params'] = {}
    return this.http.get<IDisplayTemplate[]>(this.environment.apiEndpoint + apiURLs.displayTemplatesUrlV3, httpOptions)
  }
  loadDisplayTemplate(params): Observable<IDisplayTemplate> {
    httpOptions['params'] = params
    return this.http.get<IDisplayTemplate>(this.environment.apiEndpoint + apiURLs.displayTemplatesUrlV3, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IDisplayTemplate>('loadDisplayTemplate'))
    )
  }

  updateDisplayTemplate(display_template, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.displayTemplatesUrlV3, display_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('display_template')),
      catchError(this.handleError<any>('updateDisplayTemplate'))
    )
  }

  createDisplayTemplate(display_template, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.displayTemplatesUrlV3, display_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('display_template')),
      catchError(this.handleError<any>('createDisplayTemplate'))
    )
  }

  deleteDisplayTemplate(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiURLs.displayTemplatesUrlV3, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('display_template')),
      catchError(this.handleError<any>('deleteDisplayTemplate'))
    )
  }
  /***********************************************************/
  /*                  Menu Print Template                    */
  /***********************************************************/

  loadMenuPrintTemplates(params): Observable<IMenuPrintTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<IMenuPrintTemplate[]>(this.environment.apiEndpoint + apiURLs.menuPrintTemplateUrl, httpOptions).pipe(catchError(this.handleError<IMenuPrintTemplate[]>('loadMenuPrintTemplates')))
  }

  createMenuPrintTemplate(menuPrintTemplate: IMenuPrintTemplate, params: any): Observable<IMenuPrintTemplate> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.menuPrintTemplateUrl, menuPrintTemplate, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_print_template')),
      catchError(this.handleError<any>('createMenuPrintTemplate'))
    )
  }

  updateMenuPrintTemplate(menuPrintTemplate: IMenuPrintTemplate): Observable<IMenuPrintTemplate> {
    httpOptions['params'] = {}
    return this.http.put(this.environment.apiEndpoint + apiURLs.menuPrintTemplateUrl, menuPrintTemplate, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_print_template')),
      catchError(this.handleError<any>('createMenuPrintTemplate'))
    )
  }

  deleteMenuPrintTemplate(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiURLs.menuPrintTemplateUrl, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_print_template')),
      catchError(this.handleError<any>('deleteMenuPrintTemplate'))
    )
  }

  /***********************************************************/
  /*                      BLOB Files                         */
  /***********************************************************/

  getBlobFiles(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiURLs.blob_files, httpOptions).pipe(catchError(this.handleError<any[]>('getImage')))
  }

  uploadBlobFile(formData: FormData, params): Observable<any> {
    const http_options = {
      params: params
    }
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.blob_files, formData, http_options).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('blob_files')),
      catchError(this.handleError<any>('uploadImage'))
    )
  }

  deleteBlobFile(params): Observable<string> {
    const http_options = {
      params: params
    }
    return this.http.delete<string>(this.environment.apiEndpoint + apiURLs.blob_files, http_options).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('blob_files')),
      catchError(this.handleError<string>('deleteBlobFile'))
    )
  }

  loadFileFromUrl(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(catchError(this.handleError<any>('loadFileFromUrl')))
  }
  /***********************************************************/
  /*                         Menus                           */
  /***********************************************************/

  getMenus(params): Observable<IMenu[]> {
    httpOptions['params'] = params
    return this.http.get<IMenu[]>(this.environment.apiEndpoint + apiURLs.menusUrlV3, httpOptions).pipe(catchError(this.handleError<IMenu[]>('getMenus')))
  }

  getMenusOpen(params): Observable<IMenu[]> {
    httpOptions['params'] = params
    return this.http.get<IMenu[]>(this.environment.apiEndpoint + apiURLs.menus_open, httpOptions).pipe(catchError(this.handleError<IMenu[]>('getMenus')))
  }

  createMenuWithSectionAndDish(params, menu_dict): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.menusUrlV3, menu_dict, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('createMenuWithSectionAndDish'))
    )
  }

  updateMenu(params, menu: IMenu): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.menusUrlV3, menu, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('updateMenu'))
    )
  }

  createMenu(params, menu: IMenu): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.menusUrlV3, menu, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('createMenu'))
    )
  }

  deleteMenu(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete<any>(this.environment.apiEndpoint + apiURLs.menusUrlV3, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('menu')
      })
    )
  }
  /***********************************************************/
  /*                     Menu sections                       */
  /***********************************************************/

  deleteMenuSection(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete<any>(this.environment.apiEndpoint + apiURLs.menu_sections, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
      })
    )
  }

  /***********************************************************/
  /*                     Translation                         */
  /***********************************************************/

  getTranslationSuggestions(translations, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.translationSuggestionsUrlV3, translations, httpOptions).pipe(catchError(this.handleError<any>('getTranslationSuggestions')))
  }

  saveTranslations(body, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiURLs.translations, body, httpOptions).pipe(catchError(this.handleError<any>('saveTranslations')))
  }

  /***********************************************************/
  /*                   Trash templates                       */
  /***********************************************************/

  loadTrashTemplates(params): Observable<ITrashTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<ITrashTemplate[]>(this.environment.apiEndpoint + apiURLs.trash_templates, httpOptions).pipe()
  }

  createTrashTemplate(trash_template: ITrashTemplate, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.trash_templates, trash_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('trash_template')),
      catchError(this.handleError<any>('createTrashTemplate'))
    )
  }

  updateTrashTemplate(trash_template: ITrashTemplate, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiURLs.trash_templates, trash_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('trash_template')),
      catchError(this.handleError<any>('createTrashTemplate'))
    )
  }

  /***********************************************************/
  /*                       Trackings                          */
  /***********************************************************/

  getAdminTrackings(params: object): Observable<ITracking[] | number> {
    httpOptions['params'] = params
    return this.http.get<ITracking[] | number>(this.environment.apiEndpoint + apiURLs.admin_trackings, httpOptions).pipe(catchError(this.handleError<ITracking[] | number>('getTrackings')))
  }

  loadTrackings(params): Observable<ITracking[]> {
    httpOptions['params'] = params
    return this.http.get<ITracking[]>(this.environment.apiEndpoint + apiURLs.trackings, httpOptions).pipe(catchError(this.handleError<ITracking[]>('loadTrackings')))
  }

  createTracking(tracking: ITracking, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.trackings, tracking, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking')),
      catchError(this.handleError<any>('createTracking'))
    )
  }

  updateTracking(tracking: ITracking, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiURLs.trackings, tracking, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking')),
      catchError(this.handleError<any>('updateTracking'))
    )
  }

  deleteTracking(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiURLs.trackings, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking')),
      catchError(this.handleError<any>('deleteTracking', true))
    )
  }

  loadTrackingGroups(params): Observable<ITrackingGroup[]> {
    httpOptions['params'] = params
    return this.http.get<ITrackingGroup[]>(this.environment.apiEndpoint + apiURLs.tracking_groups, httpOptions).pipe(catchError(this.handleError<ITrackingGroup[]>('loadTrackingGroups')))
  }

  updateTrackingGroups(trackingGroups: ITrackingGroup[]): Observable<ITrackingGroup[]> {
    httpOptions['params'] = {}
    return this.http.put<ITrackingGroup[]>(this.environment.apiEndpoint + apiURLs.tracking_groups, trackingGroups, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking_group')))
  }

  /***********************************************************/
  /*                         Tags                            */
  /***********************************************************/

  getTags(params?): Observable<ITag[]> {
    httpOptions['params'] = params
    return this.http.get<ITag[]>(this.environment.apiEndpoint + apiURLs.tags, httpOptions)
  }

  /***********************************************************/
  /*                         Procurement                            */
  /***********************************************************/

  getProcurementData(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.get<any>(this.environment.apiEndpoint + apiURLs.procurement_data, httpOptions).pipe(catchError(this.handleError<any>('getProcurementData')))
  }

  matchProcurementFileColumns(headers, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.ai_column_matching, headers, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('ai_column_matching')),
      catchError(this.handleError<any>('matchProcurementFileColumns'))
    )
  }

  createProcurementData(procurement_file: IProcurementDataFile, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.procurement_data, procurement_file, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement_data')),
      catchError(this.handleError<any>('createProcurementData'))
    )
  }

  updateProcurementData(procurement_product: IProcurementProduct, params): Observable<IProcurementProduct[]> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiURLs.procurement_data, procurement_product, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement_data')),
      catchError(this.handleError<any>('updateProcurementData'))
    )
  }

  deleteProcurementData(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiURLs.procurement_data, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement_data')),
      catchError(this.showErrorSnackBar()),
      catchError(this.handleError<any>('deleteProcurementData'))
    )
  }

  /***********************************************************/
  /*                     Bowl setups                         */
  /***********************************************************/

  getBowlSetupsForSubsidiary(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiURLs.bowl_setups, httpOptions).pipe(catchError(this.handleError<any[]>('getBowlSetupsForSubsidiary')))
  }

  createBowlSetup(bowl_setup: IBowlSetup, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.bowl_setups, bowl_setup, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('createBowlSetup'))
    )
  }

  updateBowlSetup(bowl_setup: IBowlSetup, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiURLs.bowl_setups, bowl_setup, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('updateBowlSetup'))
    )
  }

  deleteBowlSetup(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiURLs.bowl_setups, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('deleteBowlSetup'))
    )
  }

  /***********************************************************/
  /*                   Selected Bowls                        */
  /***********************************************************/
  updateSelectedBowlSetups(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.selected_bowl_setups, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('updateSelectedBowlSetups'))
    )
  }
  updateSelectedTrashBowls(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.selected_trash_bowls, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('trash_bowl')),
      catchError(this.handleError<any>('updateSelectedTrashBowls'))
    )
  }
  /***********************************************************/
  /*                         Bowl                            */
  /***********************************************************/

  getBowlsForSubsidiary(params): Observable<IBowl[]> {
    httpOptions['params'] = params
    return this.http.get<IBowl[]>(this.environment.apiEndpoint + apiURLs.bowls, httpOptions).pipe(catchError(this.handleError<IBowl[]>('getBowlsForSubsidiary')))
  }

  createBowl(bowl: IBowl, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiURLs.bowls, bowl, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl')),
      catchError(this.handleError<any>('createBowl'))
    )
  }

  updateBowl(bowl: IBowl, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiURLs.bowls, bowl, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl')),
      catchError(this.handleError<any>('updateBowl'))
    )
  }

  deleteBowl(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiURLs.bowls, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl')),
      catchError(this.handleError<any>('deleteBowl'))
    )
  }

  /***********************************************************/
  /*                        Gateways                         */
  /***********************************************************/
  loadGateways(params): Observable<object> {
    httpOptions['params'] = params
    return this.http.get<object>(this.environment.apiEndpoint + apiURLs.gatewaysAdminURL, httpOptions).pipe(catchError(this.handleError<object>('loadGateways')))
  }

  saveGateway(scale: IGateway): Observable<IGateway> {
    httpOptions['params'] = {}
    return this.http.put<IGateway>(this.environment.apiEndpoint + apiURLs.gatewaysAdminURL, scale, httpOptions).pipe(catchError(this.handleError<IGateway>('saveGateway')))
  }

  requestMenderInformation(gateway_id: string): Observable<any> {
    httpOptions['params'] = { gateway_id }
    return this.http.get<IGateway>(this.environment.apiEndpoint + apiURLs.mender_status, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IGateway>('requestMenderInformation'))
    )
  }

  /***********************************************************/
  /*                          Scales                         */
  /***********************************************************/
  loadScales(params): Observable<object> {
    httpOptions['params'] = params
    return this.http.get<object>(this.environment.apiEndpoint + apiURLs.scalesAdminURL, httpOptions).pipe(catchError(this.handleError<object>('loadScales')))
  }
  loadScale(params): Observable<IScale> {
    httpOptions['params'] = params
    return this.http.get<IScale>(this.environment.apiEndpoint + apiURLs.scalesAdminURL, httpOptions).pipe(
      map((result) => result['scales'][0]),
      catchError(this.handleError<IScale>('loadScale'))
    )
  }

  saveScale(scale: IScale): Observable<IScale> {
    httpOptions['params'] = {}
    return this.http.put<IScale>(this.environment.apiEndpoint + apiURLs.scalesAdminURL, scale, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IScale>('saveScale'))
    )
  }

  swapScales(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiURLs.scaleSwapURL, null, httpOptions).pipe(catchError(this.handleError<any>('swapScales')))
  }

  /***********************************************************/
  /*                     Scale Dishes                        */
  /***********************************************************/

  getSubsidiaryOpen(params): Observable<ISubsidiary> {
    httpOptions['params'] = params
    return this.http.get<ISubsidiary>(this.environment.apiEndpoint + apiURLs.subsidiaries_open, httpOptions).pipe(
      map((subsidiaries) => subsidiaries[0]),
      catchError(this.handleError<ISubsidiary>('getSubsidiaryOpen'))
    )
  }

  getLocationOpen(params): Observable<ILocation> {
    httpOptions['params'] = params
    return this.http.get<ILocation>(this.environment.apiEndpoint + apiURLs.locations_open, httpOptions).pipe(
      map((locations) => locations[0]),
      catchError(this.handleError<ILocation>('getLocationOpen'))
    )
  }

  /***********************************************************/
  /*                     Scale Dishes                        */
  /***********************************************************/

  getScaleDishes(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiURLs.admin_scale_dishes, httpOptions).pipe(catchError(this.handleError<any[]>('getScaleDishes')))
  }

  /***********************************************************/
  /*                     AccessRoles                         */
  /***********************************************************/

  getAllAccessRoles(params): Observable<IAccessRole[]> {
    httpOptions['params'] = params
    return this.http.get<IAccessRole[]>(this.environment.apiEndpoint + apiURLs.accessRolesURL, httpOptions).pipe(catchError(this.handleError<IAccessRole[]>('getAllAccessRoles')))
  }

  /***********************************************************/
  /*                  Data download                          */
  /***********************************************************/
  getSubsidiaryData(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiURLs.subsidiaryDataUrl, httpOptions).pipe(catchError(this.handleError<any[]>('getSubsidiaryData')))
  }

  /***********************************************************/
  /*            Websocket notifications                      */
  /***********************************************************/

  postNotification(data: INotificationPOSTRequest) {
    return this.http.post(this.environment.apiEndpoint + apiURLs.notificationUrl, data, httpOptions)
  }
  /***********************************************************/
  /*            API Error Handling                           */
  /***********************************************************/

  logError(error: any) {
    return this.http.post(this.environment.apiEndpoint + apiURLs.frontend_errors, error, httpOptions)
  }

  errors = {}

  handleError<T>(operation = 'operation', return_error?: boolean) {
    return (error: any): Observable<T> => {
      // TODO: better job of transforming error for user consumption
      if (this.errors[operation]) this.errors[operation] += 1
      else this.errors[operation] = 1

      console.log(`${operation} failed: ${error.message} ${this.errors[operation]}`)
      console.error(error) // log to console instead

      // Let the app keep running by returning an empty result.
      return of(return_error ? (error as T) : null)
    }
  }

  showErrorSnackBar<T>(result?: T) {
    return (error: any): Observable<T> => {
      if (error?.error?.Code && error?.error?.Message) {
        this.snackBar.open(error.error.Code + '\n' + error.error.Message, null, {
          horizontalPosition: 'center',
          panelClass: ['snackbar']
        })
      } else {
        this.snackBar.open(error.name + ': ' + error.statusText)
      }
      return of(result as T)
    }
  }
}
