import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, tap, catchError } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { RawIngredient } from './raw-ingredient.model'
import { IRawIngredient } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class RawIngredientsService {
  raw_ingredients = []

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService) {}

  public searchIngredients(name: string, offset: number, allSources?: boolean): Observable<{ ingredients: RawIngredient[]; products: RawIngredient[] }> {
    if (!name || name.length <= 1) {
      console.log('Search term too short: ' + name)
      return of({ ingredients: [], products: [] })
    } else {
      console.log('Querying DB for search term: ' + name)
      const params = {
        q: encodeURIComponent(name),
        limit: 10,
        offset: offset,
        language: this.language,
        fields: ['names'],
        all_sources: allSources
      }
      return this.restApiService.getRawIngredients(params).pipe(
        catchError(() => of({ ingredients: [], products: [] })),
        map((foods: { ingredients: IRawIngredient[]; products: IRawIngredient[] }) => {
          return {
            ingredients: foods.ingredients.map((ingredient) => new RawIngredient(ingredient)),
            products: foods.products.map((product) => new RawIngredient(Object.assign(product, { type: 'custom' })))
          }
        })
      )
    }
  }

  public loadCustomProductsWithExactName(name: string): Observable<RawIngredient[]> {
    const params = {
      q: encodeURIComponent(name),
      language: this.language,
      fields: ['names'],
      all_sources: true,
      exact: true
    }
    return this.restApiService.getRawIngredients(params).pipe(
      catchError(() => of([])),
      map((products: IRawIngredient[]) => products.map((product) => new RawIngredient(Object.assign(product, { type: 'custom' }))))
    )
  }

  createRawIngredient(name: string): Observable<RawIngredient> {
    const params = {
      language: this.language
    }
    const raw_ingredient: RawIngredient = new RawIngredient()
    raw_ingredient.names[this.language].setValue(name)
    raw_ingredient.type = 'custom'
    return this.restApiService.createRawIngredient(params, raw_ingredient.as_dict).pipe(
      catchError(() => {
        return of({ id: null })
      }),
      tap((response) => {
        raw_ingredient.id = response['id']
      }),
      map(() => raw_ingredient)
    )
  }

  updateRawIngredient(raw_ingredient: RawIngredient): Observable<RawIngredient> {
    const params = {
      language: this.language
    }
    return this.restApiService.createRawIngredient(params, raw_ingredient.as_dict).pipe(
      catchError(() => {
        return of({ id: null })
      }),
      tap((response) => {
        if (response['id']) {
          raw_ingredient.id = response['id']
          this.addToIngredients(raw_ingredient)
        }
      }),
      map(() => this.raw_ingredient_with_id(raw_ingredient.id))
    )
  }

  addToIngredients(raw_ingredient: RawIngredient): void {
    const existing_raw_ingredient: RawIngredient = this.raw_ingredient_with_id(raw_ingredient.id)
    if (!existing_raw_ingredient) this.raw_ingredients.push(raw_ingredient)
    else existing_raw_ingredient.patchValue(raw_ingredient.as_dict)
  }

  raw_ingredient_with_id(raw_ingredient_id: string): RawIngredient {
    return this.raw_ingredients.find((raw_ingredient) => raw_ingredient.id == raw_ingredient_id)
  }
}
