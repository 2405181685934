import { FormBuilder, FormControl } from '@angular/forms'
import { Observable, tap } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { Allergen } from '../allergen/allergen.model'
import { Names } from '../names/names.model'
import { RawIngredientsService } from './raw-ingredients.service'
import { NutritionFact } from '../nutrition/nutrition-fact.model'
import { Tag } from '../tag/tag.model'
import { TagsService, ingredientTagSorting } from '../tag/tag.service'
import { IAllergen, IName, INutritionFact, IProduct, IRawIngredient } from '../../global.models'
import { AllergensService } from '../allergen/allergens.service'
import { NutritionService } from '../nutrition/nutrition.service'
import { required_nutrition_facts } from '../../global.types'

export class RawIngredient {
  id: string
  names: Names
  ingredients: FormControl
  accessibility: FormControl
  organization_id: string
  subsidiary_id: string
  user_id: string
  co2: FormControl
  allergens: Allergen[]
  sourceIngredientId: FormControl
  sourceIngredientNames: IName

  nutrition_base_unit: FormControl
  nutrition_base_preparation: FormControl
  nutrition_base_value: FormControl
  nutrition: NutritionFact[]

  menu_dishes: any[]
  lock: boolean
  estimated_share: number // Estimated share from db

  default_shrinkage: number // Estimated shrinkage in % going fra uncleaned raw-ingredient to cleanded raw-ingredient
  shrinkage: FormControl // Estimated shrinkage in % going fra uncleaned raw-ingredient to cleanded raw-ingredient
  preparation_factor: FormControl // Estimated weight factor going from cleaned raw-ingredient to prepared ingredient
  netto_factor: FormControl // Combined factor going from uncleaned raw-ingredient to prepared ingredient
  unit: FormControl

  portion_id: string

  type: string
  source: any

  loading = false
  saving = false
  saved_ingredient: string

  // procurement/production variables:
  products: IProduct[]
  all_products: IProduct[]
  provider: FormControl
  product_number: FormControl
  price: FormControl
  tags: Tag[]
  gtin: FormControl
  gtins: string[]
  in_stock: FormControl

  updated: string
  created: string
  search_name: string

  potential_duplicates: RawIngredient[]

  rawIngredientsService: RawIngredientsService
  allergensService: AllergensService
  nutritionService: NutritionService
  tagsService: TagsService
  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public raw_ingredient?: IRawIngredient) {
    this.rawIngredientsService = FoodopLibModule.injector.get(RawIngredientsService)
    this.allergensService = FoodopLibModule.injector.get(AllergensService)
    this.nutritionService = FoodopLibModule.injector.get(NutritionService)
    this.tagsService = FoodopLibModule.injector.get(TagsService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.id = raw_ingredient?.id
    this.names = new Names(raw_ingredient?.names)
    this.co2 = this.fb.control(raw_ingredient?.co2)
    this.unit = this.fb.control(raw_ingredient?.unit || 'kg')
    this.estimated_share = 10
    this.lock = false
    this.createAllergensFromArray(raw_ingredient?.allergens || [])

    this.nutrition_base_unit = this.fb.control('GRM')
    this.nutrition_base_preparation = this.fb.control('UNPREPARED')
    this.nutrition_base_value = this.fb.control(100)
    this.nutrition = this._createNutritionFactsFromArray(raw_ingredient?.nutrition || [])

    this.default_shrinkage = raw_ingredient?.default_shrinkage || 1
    this.shrinkage = this.fb.control(raw_ingredient?.shrinkage?.toString() || '1')
    this.preparation_factor = this.fb.control(raw_ingredient?.preparation_factor?.toString() || '1')
    this.netto_factor = this.fb.control(raw_ingredient?.netto_factor?.toString() || '1')

    this.provider = this.fb.control(raw_ingredient?.provider)
    this.product_number = this.fb.control(raw_ingredient?.product_number)
    this.price = this.fb.control(raw_ingredient?.price)
    this.tags = (raw_ingredient?.tags || []).map((tag) => new Tag(tag))
    this.gtin = this.fb.control(raw_ingredient?.type == 'generic' ? null : Array.isArray(raw_ingredient?.gtin) ? (raw_ingredient?.gtin.length ? raw_ingredient?.gtin[0] : null) : raw_ingredient?.gtin)
    this.gtins = raw_ingredient?.gtin
    this.type = raw_ingredient?.type
    this.source = raw_ingredient?.source
    this.sourceIngredientId = this.fb.control(raw_ingredient?.ingredient?.id)
    this.sourceIngredientNames = raw_ingredient?.ingredient?.names

    this.accessibility = this.fb.control(raw_ingredient?.accessibility || 0)
    this.organization_id = raw_ingredient?.organization_id
    this.subsidiary_id = raw_ingredient?.subsidiary_id
    this.user_id = raw_ingredient?.user_id
    this.updated = raw_ingredient?.updated
    this.created = raw_ingredient?.created
    this.search_name = raw_ingredient?.search_name

    this.in_stock = this.fb.control(false)

    this.saveIngredientObject()
  }

  patchValue(raw_ingredient: IRawIngredient): void {
    if (raw_ingredient?.names) this.names.patchValue(raw_ingredient?.names)
    if (raw_ingredient?.co2 != undefined) this.co2.setValue(raw_ingredient.co2, { emitEvent: false })
    if (raw_ingredient?.allergens?.length) this.updateAllergensFromArray(raw_ingredient?.allergens)
    if (raw_ingredient?.nutrition) this.nutrition = this._createNutritionFactsFromArray(raw_ingredient?.nutrition)
    if (raw_ingredient?.provider) this.provider.setValue(raw_ingredient.provider, { emitEvent: false })
    if (raw_ingredient?.product_number) this.product_number.setValue(raw_ingredient.product_number, { emitEvent: false })
    if (raw_ingredient?.price) this.price.setValue(raw_ingredient.price, { emitEvent: false })
    if (raw_ingredient?.tags) this.tags = (raw_ingredient?.tags || []).map((tag) => new Tag(tag))
    if (raw_ingredient?.gtin) this.gtin.setValue(raw_ingredient.gtin, { emitEvent: false })
    if (raw_ingredient?.type) this.type = raw_ingredient.type
    if (raw_ingredient?.source) this.source = raw_ingredient?.source
    if (raw_ingredient?.in_stock) this.in_stock.setValue(raw_ingredient.in_stock, { emitEvent: false })
    if (raw_ingredient?.ingredient) {
      this.sourceIngredientId.setValue(raw_ingredient.ingredient.id, { emitEvent: false })
      this.sourceIngredientNames = raw_ingredient.ingredient.names
    }

    this.saveIngredientObject()
  }

  createAllergensFromArray(allergen_list: IAllergen[]): void {
    this.allergens = this.allergensService.required_allergens.map((required_allergen) => {
      return new Allergen(allergen_list.find((allergen) => allergen.code == required_allergen.code) || required_allergen.as_dict)
    })
  }

  updateAllergensFromArray(allergen_list: IAllergen[]): void {
    this.allergens.forEach((allergen) => {
      const matched_allergen = allergen_list.find((updated_allergen) => updated_allergen.code == allergen.code)
      if (matched_allergen) allergen.containment.setValue(matched_allergen.containment == 'undeclared' ? null : matched_allergen?.containment?.toLowerCase(), { emitEvent: false })
    })
  }

  public loadPotentialDuplicates(language: string): Observable<RawIngredient[]> {
    return this.rawIngredientsService.loadCustomProductsWithExactName(this.names[language].value).pipe(
      tap((products) => {
        this.potential_duplicates = products
      })
    )
  }

  // Getters
  get duplicate(): RawIngredient {
    return this.potential_duplicates?.find((raw_ingredient) => raw_ingredient.id != this.id && JSON.stringify(raw_ingredient.sortedTags.map((tag) => tag.asDict)) == JSON.stringify(this.sortedTags.map((tag) => tag.asDict)))
  }

  get changed(): boolean {
    return this.saved_ingredient != JSON.stringify(this.as_dict)
  }

  get filtered_nutrition(): NutritionFact[] {
    return this.nutrition.filter((nutrition_fact) => nutrition_fact.preparation.value == this.nutrition_base_preparation.value && nutrition_fact.base_unit.value == this.nutrition_base_unit.value && nutrition_fact.base_value.value == this.nutrition_base_value.value)
  }

  isNameTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  public get usedTags(): Tag[] {
    return this.tags.filter((tag) => tag.id)
  }

  public get sortedTags(): Tag[] {
    return this.usedTags.sort((a, b) => {
      return ingredientTagSorting[a.id] <= ingredientTagSorting[b.id] ? -1 : 1
    })
  }

  public hasTag(tag: Tag): boolean {
    return this.tags.find((addedTag) => addedTag.id == tag.id) != undefined
  }
  public addTag(tag: Tag): void {
    this.tags.push(tag)
  }
  public removeTag(tag: Tag): void {
    const index: number = this.tags.findIndex((added_tag) => added_tag.id == tag.id)
    if (index >= 0) {
      this.tags.splice(index, 1)
    }
  }

  product_with_gtin(gtin: string): any {
    return this.all_products.find((product) => product.gtin == gtin)
  }

  nutrition_fact_with_type_code_value(type_code_value: string): NutritionFact {
    return this.nutrition.find(
      (nutrition_fact) =>
        nutrition_fact.nutrition_type_code_value == type_code_value &&
        nutrition_fact.base_unit.value == this.nutrition_base_unit.value &&
        nutrition_fact.preparation.value == this.nutrition_base_preparation.value &&
        nutrition_fact.base_value.value == this.nutrition_base_value.value
    )
  }

  allergen_with_code(allergen_code: string): any {
    return this.allergens.find((allergen) => allergen.code == allergen_code)
  }

  update_nutrition_based_on_gtin(gtin: string): void {
    const product = this.product_with_gtin(gtin)
    if (product?.nutrition) this._createNutritionFactsFromArray(product?.nutrition)
  }

  update_allergens_based_on_gtin(gtin: string): void {
    const product = this.product_with_gtin(gtin)
    if (product?.allergens) this.updateAllergensFromArray(product.allergens)
  }

  _createNutritionFactsFromArray(nutrition_facts_list: INutritionFact[]): NutritionFact[] {
    return required_nutrition_facts.map((required_nutrition_fact) => {
      const match = nutrition_facts_list.find(
        (nutrition_fact) =>
          nutrition_fact.nutrition_type_code_value == required_nutrition_fact.nutrition_type_code_value &&
          ((required_nutrition_fact.nutrition_type_code_value == 'ENER-' && nutrition_fact.unit == 'E14') || required_nutrition_fact.nutrition_type_code_value != 'ENER-') &&
          nutrition_fact.base_unit == this.nutrition_base_unit.value &&
          (nutrition_fact.preparation || 'UNPREPARED') == this.nutrition_base_preparation.value &&
          nutrition_fact.base_value == this.nutrition_base_value.value
      )
      return new NutritionFact(match, required_nutrition_fact)
    })
  }

  // CRUD operations
  restore(): void {
    this.patchValue(JSON.parse(this.saved_ingredient))
  }

  saveIngredientObject() {
    this.saved_ingredient = JSON.stringify(this.as_dict)
  }

  save(): Observable<RawIngredient> {
    this.saving = true
    if (this.type != 'custom') {
      this.sourceIngredientId.setValue(this.id, { emitEvent: false })
      this.sourceIngredientNames = this.names.as_dict
      this.id = undefined
      this.type = 'custom'
    }
    return this.rawIngredientsService.updateRawIngredient(this).pipe(
      tap(() => {
        this.saveIngredientObject()
        this.saving = false
      })
    )
  }

  // JSON objects
  get as_dict(): IRawIngredient {
    return {
      id: this.id,
      names: this.names.as_dict,
      co2: this.co2.value,
      allergens: this.allergens.map((allergen) => allergen.as_dict),
      nutrition: this.nutrition.map((nutrition_fact) => nutrition_fact.as_dict),
      default_shrinkage: this.default_shrinkage,
      provider: this.provider.value,
      product_number: this.product_number.value,
      price: this.price.value,
      tags: this.tags.map((tag) => tag.asDict),
      gtin: this.type == 'generic' ? this.gtins : this.gtin.value,
      type: this.type,
      source: this.source,
      ingredient: this._sourceIngredientDict,
      accessibility: this.accessibility.value,
      organization_id: this.organization_id,
      subsidiary_id: this.subsidiary_id,
      user_id: this.user_id,
      created: this.created,
      updated: this.updated,
      search_name: this.search_name
    }
  }
  get as_recipe_ingredient_dict(): IRawIngredient {
    return {
      id: this.id,
      names: this.names.as_dict,
      co2: this.co2.value,
      allergens: this.allergens.map((allergen) => allergen.as_dict),
      nutrition: this.nutrition.map((nutrition_fact) => nutrition_fact.as_dict),
      unit: this.unit.value,
      default_shrinkage: this.default_shrinkage,
      shrinkage: this.func.roundString(this.shrinkage.value.toString()),
      preparation_factor: this.func.roundString(this.preparation_factor.value.toString()),
      netto_factor: this.func.roundString(this.netto_factor.value.toString()),
      provider: this.provider.value,
      product_number: this.product_number.value,
      price: this.price.value,
      tags: this.tags.map((tag) => tag.asDict),
      gtin: this.type == 'generic' ? this.gtins : this.gtin.value,
      type: this.type,
      ingredient: this._sourceIngredientDict,
      accessibility: this.accessibility.value,
      organization_id: this.organization_id,
      subsidiary_id: this.subsidiary_id,
      user_id: this.user_id,
      created: this.created,
      updated: this.updated,
      search_name: this.search_name,
      in_stock: this.in_stock.value
    }
  }

  private get _sourceIngredientDict(): any {
    if (this.sourceIngredientId.value) {
      return {
        id: this.sourceIngredientId.value,
        names: this.sourceIngredientNames
      }
    } else return undefined
  }
}
