import { Injectable } from '@angular/core'
import { Observable, Subject, map, merge, scan, tap } from 'rxjs'
import { RestApiService } from './rest-api.service'

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private _newCustomerImages$: Subject<any> = new Subject<any>()
  public customerImages$: Observable<any[]> = this.getFileUrls(this._newCustomerImages$, 'images')
  private _newCustomerFonts$: Subject<any> = new Subject<any>()
  public customerFonts$: Observable<any[]> = this.getFileUrls(this._newCustomerFonts$, 'fonts')

  constructor(private restApiService: RestApiService) {}

  public getFileUrls(newFiles: Subject<any>, fileType: string): Observable<any[]> {
    let params = {
      file_type: fileType
    }
    return merge(newFiles, this.restApiService.getBlobFiles(params)).pipe(
      scan((customerFiles: any[], newCustomerFiles: any[]) => {
        const map = new Map()
        customerFiles.forEach((file) => map.set(file.file_id, file))
        newCustomerFiles.forEach((file) => map.set(file.file_id, { ...map.get(file.file_id), ...file }))
        return Array.from(map.values())
      }),
      tap((files) => {
        if (fileType === 'fonts') {
          files.forEach((font) => this._loadAndInstallFont(font))
        }
      })
    )
  }

  public getFileUrl(fileIds: string[], fileType: string): Observable<string> {
    let params = {
      file_type: fileType,
      file_ids: fileIds
    }
    return this.restApiService.getBlobFiles(params).pipe(
      tap((files) => {
        if (fileType === 'fonts') {
          const map = new Map()
          files.forEach((file) => map.set(file.file_id, file))
          let fonts = Array.from(map.values())
          fonts.forEach((font) => this._loadAndInstallFont(font))
        }
      }),
      map((files) => {
        return files.find((file) => file.file_id == fileIds[0])?.blob_url
      })
    )
  }

  public uploadFile(fileToUpload: File, fileType: string): Observable<string> {
    const formData: FormData = new FormData()
    formData.append('file', fileToUpload, fileToUpload.name)
    const params = {
      file_type: fileType
    }
    return this.restApiService.uploadBlobFile(formData, params).pipe(
      tap((reponse) => {
        if (fileType == 'images') this._newCustomerImages$.next(reponse)
        if (fileType == 'fonts') this._newCustomerFonts$.next(reponse)
      }),
      map((response) => Object.keys(response)[0])
    )
  }

  public deleteFile(fileIds: string[], fileType: string): Observable<string> {
    const params = {
      file_ids: fileIds,
      file_type: fileType
    }
    return this.restApiService.deleteBlobFile(params)
  }

  public downloadExcelFile(filename: string, headerRow: string[], dateRows: any[], unitList?: string[]): void {
    let csvData = this._convertToCSV(dateRows, headerRow, unitList)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' })
    let dwldLink = document.createElement('a')
    let url = URL.createObjectURL(blob)
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank')
    }
    dwldLink.setAttribute('href', url)
    dwldLink.setAttribute('download', filename + '.csv')
    dwldLink.style.visibility = 'hidden'
    document.body.appendChild(dwldLink)
    dwldLink.click()
    document.body.removeChild(dwldLink)
  }

  private _convertToCSV(dataRows: any[], headerRow: string[], unitList?: string[]): string {
    // Rows
    let str = ''
    let row = ''

    // Header row:
    headerRow.forEach((header, idx) => {
      row += (idx > 0 ? ';' : '') + header + (unitList && unitList[idx] ? ' [' + unitList[idx] + ']' : '')
    })

    row = row.slice(0, -1)
    str += row + '\r\n'

    // Data rows:
    dataRows.forEach((dataRow: string[]) => {
      dataRow.join(';')
      // Line break
      str += dataRow.join(';') + '\r\n'
    })
    return str
  }

  private _loadAndInstallFont(font: any): void {
    this.restApiService.loadFileFromUrl(font.blob_url).subscribe((file) => {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        let customFont = new FontFace(font.file_id, "url('" + e.srcElement.result + "') format('opentype')")
        customFont
          .load()
          .then((res) => {
            ;(document.fonts as any).add(res)
          })
          .catch((error) => {
            console.log(error)
          })
      }
      reader.readAsDataURL(file)
    })
  }
}
